<template>
  <div class="container">

    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-3">
      <h4 class="text-center my-3">Транскрипт</h4>

      <!-- transcript -->
      <div v-if="Object.keys(studentInfos).length">

        <!-- info section -->
        <div class="card my-3">
          <div class="card-body">
            <div class="card-title mb-4">
              <h5>Информация о студенте</h5>
            </div>

            <div>
              <p>
                <b>ФИО:</b> {{ studentInfos?.last_name }} {{ studentInfos?.first_name }} {{ studentInfos?.middle_name }}
              </p>
              <p><b>Баркод:</b> {{ studentInfos?.barcode }}</p>
              <p>
                <b>Специальность или ОП:</b> {{ studentInfos?.educationProgram?.education_speciality_code }}
                {{ studentInfos?.educationProgram?.education_speciality_name != 'Smart medicine' ? studentInfos?.educationProgram?.education_speciality_name : 'General medicine'}}
              </p>
              <p><b>Уровень обучения:</b> {{ studentInfos?.studyLevel?.name }}</p>
              <p><b>Форма обучения:</b> {{ studentInfos?.studyForm?.name }}</p>
              <p><b>Курс:</b> {{ studentInfos?.study_course }}</p>
              <p><b>Группа:</b> {{ studentInfos?.studentGroups?.name }}</p>
            </div>
          </div>
        </div>
        <!-- end info section -->

        <!-- transcript section -->
        <div v-if="Object.keys(transcriptInfos).length">

          <!-- print transcript section -->
          <div v-if="!decryptCheckRole('student')" class="my-2">
            <a class="btn btn-primary" target="_blank" role="button"
               :href="`https://back.uib.kz/student/transcript/print-transcript?barcode=${urlBarcode}`">
              Распечатать
            </a>
          </div>
          <!-- end print transcript section -->

          <div v-if="decryptCheckRole('admin') || decryptCheckRole('officeRegistrationHead') || decryptCheckRole('officeRegistration')"
               class="my-2">
            <Button label="Добавить особые отметки" class="p-button-primary"
                    @click="openStudentViolationsDialog"/>
          </div>

          <div class="table-responsive mt-4">
            <table class="table table-striped table-bordered border-dark">
              <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">Название дисциплины</th>
                <th scope="col">Кредит (ECTS)</th>
                <th scope="col">Общий балл</th>
                <th scope="col">Баллы GPA</th>
                <th scope="col">Оценка</th>
              </tr>
              </thead>
              <tbody v-for="(semesters,semesterKey) in transcriptInfos" :key="semesterKey">

              <template v-if="semesters.ratings.length">
                <tr class="sem_tr">
                  <td colspan="6">
                    <span v-if="semesterKey==0">Пререквизиты</span>
                    <span v-else>{{ semesterKey }} семестр, {{ semesterKey % 2 === 0 ? 'Весна' : 'Осень' }}</span>

                  </td>
                </tr>
                <tr v-for="(semesterDisciplines, index) in semesters.ratings" :key="index"
                    :class="{ td_first: (index%2 === 0), fx_td: (semesterDisciplines.mark == 'FX') }">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ semesterDisciplines.discipline_name }}</td>
                  <td>{{ semesterDisciplines.credit }}</td>
                  <td>{{ semesterDisciplines.total }}</td>
                  <td>{{ semesterDisciplines.gpa }}</td>
                  <td>{{ semesterDisciplines.mark }}</td>
                </tr>
              </template>

              <tr class="sem_gpa">
                <td colspan="2" class="text-end">Количество кредитов за семестр</td>
                <td>{{ calculateSemesterCredit(semesterKey) }}</td>
                <td class="text-end">Семестровый GPA</td>
                <td>{{ calculateSemesterGpa(semesterKey) }}</td>
                <td></td>
              </tr>

              <tr class="course_gpa" v-if="semesterKey%2===0 && semesterKey != 0">
                <td colspan="2" class="text-end">Количество кредитов за год</td>
                <td>{{ calculateYearCredit(semesterKey) }}</td>
                <td class="text-end">Годовой GPA</td>
                <td>{{ calculateYearGpa(semesterKey) }}</td>
                <td></td>
              </tr>

              <template v-if="semesters.summer_ratings.length">
                <tr class="sem_tr">
                  <td colspan="6">
                    Летний семестр
                  </td>
                </tr>
                <tr v-for="(semesterDisciplines, index) in semesters.summer_ratings" :key="index"
                    :class="{ td_first: (index%2 === 0), fx_td: (semesterDisciplines.mark == 'FX') }">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ semesterDisciplines.discipline_name }}</td>
                  <td>{{ semesterDisciplines.credit }}</td>
                  <td>{{ semesterDisciplines.total }}</td>
                  <td>{{ semesterDisciplines.gpa }}</td>
                  <td>{{ semesterDisciplines.mark }}</td>
                </tr>
              </template>


              </tbody>
            </table>
            <table class="table table-striped table-bordered border-dark">
              <tbody>
              <tr class="total_gpa">
                <td class="text-center">Общее количество кредитов</td>
                <td class="text-center">Общий GPA</td>
              </tr>
              <tr>
                <td class="text-center">{{ allCredit }}</td>
                <td class="text-center">{{ allGpa }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
        <!-- transcript section end -->
      </div>

      <div v-else>
        <h5 class="text-center mt-3">
          Здесь пусто
        </h5>
      </div>
      <!-- end transcript -->

    </div>

    <Dialog v-model:visible="addStudentViolationsDisplay" :style="{width: '50vw'}" :closable="false">
      <template #header>
        <h5>Добавить особые отметки</h5>
      </template>

      <div class="col-md-12 mt-4">
        <div class="form-row" v-for="(studentViolationsInfo, index) in studentViolations" :key="index">
          <div class="job-remove-item__button d-flex flex-row-reverse">
            <i class="fa fa-times" @click="deleteRow(index)"></i>
          </div>


          <div class="form-group row mt-4">
            <label for="company" class="col-md-3 col-form-label">Отметки</label>
            <div class="col-md-9">
              <input id="company" type="text" class="form-control" placeholder="Отметки"
                     :value="studentViolationsInfo.violation" @change="changeViolation(index, $event)">
            </div>
          </div>
          <hr>
        </div>
        <div class="form-group">
          <button @click="addRow" type="button" class="btn btn-secondary"><i
            class="fas fa-plus-circle"></i>
            Добавить
          </button>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                @click="closeStudentViolationsDialog"/>
        <Button label="Сохранить" icon="pi pi-check" @click="postStudentViolations"/>
      </template>
    </Dialog>

  </div>
</template>

<script>
import httpClient from "@/services/http.service"
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "StudentTranscript",
  data() {
    return {
      urlBarcode: +this.$route.query.barcode || 0,
      transcriptInfos: {},
      studentInfos: {},
      summerRatings: [],
      loading: true,
      studentViolations: [],
      addStudentViolationsDisplay: false,
    }
  },
  computed: {
    allCredit() {
      let allCredit = 0
      for (let key in this.transcriptInfos) {
        // allCredit += this.calculateCredit(this.filterRatingsBySemester(key))
        //Убирает кредит пререквизитов(семестр = 0)
        if (key != 0) {
          allCredit += this.calculateCredit(this.filterRatingsBySemester(key))
        }
      }
      return allCredit
    },
    allGpa() {
      let allGpa = 0
      for (let key in this.transcriptInfos) {
        //Убирает GPA пререквизитов(семестр = 0)
        if (key != 0) {
          allGpa += this.calculateSumBall(this.filterRatingsBySemester(key))
        }
      }
      return this.allCredit === 0 ? 0 : (allGpa / this.allCredit).toFixed(1)
    },
  },
  methods: {
    decryptCheckRole,
    async postStudentViolations() {
      try {
        let newstudentViolationsPost = this.studentViolations.filter(i => i.id == null)
        if (newstudentViolationsPost) {
          const {status, data} = await httpClient.post(`student/student-violations/create`, newstudentViolationsPost)
          if (status === 200 && data) {
            this.$message({title: 'Добавлено'})
          }
        }
        let newstudentViolationsPut = this.studentViolations.filter(i => i.id != null)
        if (newstudentViolationsPut) {
          const {status, data} = await httpClient.put(`student/student-violations/update`, newstudentViolationsPut)
          if (status === 200 && data) {
            this.$message({title: 'Обновлено'})
          }
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return false
    },
    changeViolation(index, e) {
      this.studentViolations[index].violation = e.target.value
      // console.log(this.studentViolations, 'ggg')
    },
    addRow() {
      // console.log(this.studentViolations, 'ggg')
      this.studentViolations.push({
        id: null,
        student_id: this.studentInfos.id,
        violation: '',
      });
    },
    deleteRow(index) {
      if (this.studentViolations[index].id) {
        this.DELETE_VIOLATION_DATA(index);
      }
      this.studentViolations.splice(index, 1);
    },
    openStudentViolationsDialog() {
      this.addStudentViolationsDisplay = true;
    },
    closeStudentViolationsDialog() {
      this.addStudentViolationsDisplay = false;
    },
    calculateSemesterCredit(semester) {
      return this.calculateCredit(this.filterRatingsBySemester(semester))
    },
    calculateSemesterGpa(semester) {
      const semesterTranscript = this.filterRatingsBySemester(semester)
      const semesterCredit = this.calculateCredit(semesterTranscript)
      return semesterCredit ? (this.calculateSumBall(semesterTranscript) / semesterCredit).toFixed(1) : 0
    },
    calculateYearCredit(semester) {
      if (this.checkSemesterForYear(semester)) {
        return this.yearSemesters(semester)
          .map(s => this.calculateSemesterCredit(s))
          .reduce((sum, credits) => credits + sum, 0)
      }
      return 0
    },
    calculateYearGpa(semester) {
      if (this.checkSemesterForYear(semester)) {
        const yearCredits = this.calculateYearCredit(semester)
        const yearGpa = this.yearSemesters(semester)
          .map(s => this.calculateSumBall(this.filterRatingsBySemester(s)))
          .reduce((sum, gpa) => gpa + sum, 0)

        return yearCredits === 0 ? 0 : (yearGpa / yearCredits).toFixed(1)
      }
      return 0
    },
    checkSemesterForYear(semester) {
      return [2, 4, 6, 8].includes(+semester)
    },
    yearSemesters(semester) {
      return [semester - 1, semester].filter(s => !!this.transcriptInfos[s])
    },
    filterRatingsBySemester(semester) {
      return [
        ...this.transcriptInfos[semester]?.ratings.filter(i => i.calculate === '1'),
        ...this.summerRatings.filter(i => i.parent_semester == semester && i.calculate === '1')
      ]

    },
    calculateCredit(transcript) {
      return transcript.reduce((sum, i) => +i.credit + sum, 0)
    },
    calculateSumBall(transcript) {
      return transcript.reduce((sum, i) => i.gpa * i.credit + sum, 0)
    },
    async getStudentViolations() {
      try {
        const {
          status,
          data
        } = await httpClient.get(`student/student-violations/get-student-violations?student_id=${this.studentInfos.id}`)
        if (status === 200 && data) {
          return data
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return []
    },
    async getInfoByBarcode(barcode) {
      try {
        const {status, data} = await httpClient.get(`student_data/student-data/get-info-by-barcode?barcode=${barcode}`)
        if (status === 200 && data.success) {
          this.studentInfos = data.success
          return true
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return false
    },
    async getTranscriptByBarcode(barcode) {
      try {
        const {status, data} = await httpClient.get(`student/transcript/get-transcript?barcode=${barcode}`)
        if (status === 200 && data) {
          this.transcriptInfos = data
          const summerRatings = []
          Object.values(data).forEach(r => {
            summerRatings.push(...r.summer_ratings)
          })
          this.summerRatings = summerRatings
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
    },
  },
  async mounted() {
    const barcode = this.urlBarcode || getCookie('USERNAME')
    if (barcode) {
      const res = await this.getInfoByBarcode(barcode)
      if (res) {
        await this.getTranscriptByBarcode(barcode)
        this.studentViolations = await this.getStudentViolations()
      }
    }
    this.loading = false
  },
}
</script>

<style scoped>
.sem_tr {
  background: none repeat scroll 0 0 #20B2AA;
}

.sem_gpa {
  background-color: #98FB98;
  font-weight: bold;
}

.td_first {
  background: none repeat scroll 0 0 #CCFFFF;
}

.total_gpa {
  background-color: #32CD32;
  font-weight: bold;
}

.course_gpa {
  background: none repeat scroll 0 0 #f1ff7d;
  font-weight: bold;
}

.fx_td {
  background: #f17575;
}
</style>
